import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Col, Row } from "reactstrap";
import Breadcrumb from "../../components/common/breadcrumb";
import { Dragging_Event } from "../../constant";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { createSchedule, getSchedule, updateSchedule, deleteSchedule } from '../../api/index';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { format, parse } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("pt-BR", ptBR);
setDefaultLocale("pt-BR");

function Schedule() {
    const initialDate = new Date();
    initialDate.setHours(16, 0, 0, 0);

    const [hour, setHour] = useState(initialDate);
    const [idSelected, setIdSelected] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [addModal, setAddModal] = useState(false);
    const [premium, setPremium] = useState(false);
    const [loading, setLoading] = useState(false);
    const [calendarEvents, setCalendarEvents] = useState([]);

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    const handleSubmitEvent = async (data) => {
        try {
            setLoading(true);

            const payload = {
                id: idSelected,
                ...data,
                start_date: format(new Date(startDate), "MM-dd-yyyy") + " " + format(new Date(hour), "HH:mm"),
                end_date: format(new Date(endDate), "MM-dd-yyyy") + " " + format(new Date(hour), "HH:mm"),
                premium: premium
            }

            const resp = (idSelected ? await updateSchedule(payload) : await createSchedule(payload))

            if (resp?.status == 200) {
                fetchSchedules();
                setAddModal(false);
                toast.success(`Sucesso!`);
            } else {
                toast.error(`Error: ${resp?.data.msg}`);
            }
        } catch (error) {
            toast.error(`Error: ${error}`);
        }
        finally {
            setLoading(false);
        }
    }

    const fetchSchedules = async () => {
        let calendarSchedules = [];
        const { data: { msg: schedules } } = await getSchedule();

        schedules.forEach(item => {

            if (item.start_date == item.end_date) {
                calendarSchedules.push({
                    title: item.title,
                    description: item.description,
                    end_date: item.end_date,
                    link: item.link,
                    premium: item.premium,
                    start: new Date(item.start_date),
                    id: item.id,
                });
            } else {
                const startDate = new Date(item.start_date);
                const endDate = new Date(item.end_date);

                const timeDiff = Math.abs(endDate - startDate);
                const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

                for (let i = 0; i <= daysDiff; i++) {
                    const currentDate = new Date(startDate);
                    currentDate.setDate(currentDate.getDate() + i);

                    calendarSchedules.push({
                        title: item.title,
                        description: item.description,
                        end_date: item.end_date,
                        link: item.link,
                        premium: item.premium,
                        start: currentDate,
                        id: item.id,
                    });
                }
            }
        });

        setCalendarEvents(calendarSchedules);
    }

    useEffect(() => {
        const prevButton = document.querySelector(".fc-prev-button");
        const nextButton = document.querySelector(".fc-next-button");
        const todayButton = document.querySelector(".fc-today-button");

        if (prevButton) {
            prevButton.title = "Mês anterior";
        }

        if (nextButton) {
            nextButton.title = "Próximo mês";
        }

        if (todayButton) {
            todayButton.textContent = "Hoje";
        }

        fetchSchedules();
    }, []);

    const addToggle = () => {
        setAddModal(!addModal);
    };

    const dateClick = (info) => {
        setIdSelected(null);
        reset();
        const clickedDate = info.date;
        setStartDate(new Date(clickedDate));
        setEndDate(new Date(clickedDate));
        addToggle();
    };

    const handleRemoveSchedule = async (scheduleId) => {
        setLoading(true);
        const resp = await deleteSchedule(scheduleId);

        if (resp?.status == 200) {
            fetchSchedules();
            setAddModal(false);
            toast.success(`Sucesso!`);
        } else {
            toast.error(`Error: ${resp?.data.msg || resp?.status}`);
        }
        setLoading(false);
    };

    const eventClick = (eventClick) => {
        const selectedEvent = calendarEvents.find(event => event.id == eventClick.event.id)

        setStartDate(selectedEvent.start);
        setEndDate(new Date(selectedEvent.end_date));
        setHour(selectedEvent.start);
        setPremium(selectedEvent.premium);

        setValue("title", selectedEvent.title);
        setValue("description", selectedEvent.description);
        setValue("link", selectedEvent.link);
        setValue("premium", selectedEvent.premium);
        setValue("start_date", selectedEvent.start);
        setValue("end_date", (new Date(selectedEvent.end_date)));
        setValue("hour", selectedEvent.start);

        setIdSelected(selectedEvent.id);
        setAddModal(true);
    };

    const handleRadioChange = (e) => {
        const id = e.target.id;
        const isPremiumValue = id === 'premium';
        setPremium(isPremiumValue);
    };

    const handleChange = date => {
        setHour(date);
    };

    return (
        <>
            <Modal isOpen={addModal} toggle={addToggle} size="md">
                <ModalHeader>Novo Evento</ModalHeader>
                <ModalBody>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <form className="theme-form mega-form needs-validation" onSubmit={handleSubmit(handleSubmitEvent)}>
                                    <div className="form-group">
                                        <label className="col-form-label">Título</label>
                                        <input {...register("title", { required: true })} className="form-control" type="text" name="title" minLength={5} maxLength={60} placeholder="Nome" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Descrição</label>
                                        <textarea {...register("description", { required: true })} className="form-control" maxLength={200} name="description" placeholder="Descrição" />
                                    </div>
                                    <div className="form-group">
                                        <label className="col-form-label">Link</label>
                                        <input {...register("link", { required: true })} className="form-control" type="url" name="link" placeholder="Link" />
                                    </div>
                                    <hr className="mt-4 mb-4" />
                                    <div className="form-group row">
                                        <div className="col-md-4">
                                            <label className="col-form-label text-start">Horário</label>
                                            <DatePicker className="form-control digits" name="hour" selected={hour} locale="pt-BR" onChange={handleChange} showTimeSelect
                                                showTimeSelectOnly timeIntervals={15} timeCaption="Horário" dateFormat="HH:mm" timeFormat="HH:mm" />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label text-start">Data Início</label>
                                            <DatePicker
                                                className="col-md-6 form-control digits"
                                                name="start_date"
                                                locale="pt-BR"
                                                selected={startDate}
                                                onChange={(date) => setStartDate(date)}
                                                selectsStart
                                                startDate={startDate}
                                                endDate={endDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label className="col-form-label text-start">Data Fim</label>
                                            <DatePicker
                                                className="col-md-6 form-control digits"
                                                name="end_date"
                                                locale="pt-BR"
                                                selected={endDate}
                                                onChange={(date) => setEndDate(date)}
                                                selectsEnd
                                                startDate={startDate}
                                                endDate={endDate}
                                                minDate={startDate}
                                                dateFormat="dd/MM/yyyy"
                                            />
                                        </div>
                                    </div>
                                    <hr className="mt-4 mb-4" />
                                    <div className="col-sm-9">
                                        <div className="radio radio-primary ms-2">
                                            <input
                                                id="premium"
                                                type="radio"
                                                name="premium"
                                                checked={premium}
                                                onChange={handleRadioChange}
                                            />
                                            <label htmlFor="premium">Premium</label>
                                        </div>
                                        <div className="radio radio-primary ms-2">
                                            <input
                                                id="free"
                                                type="radio"
                                                name="premium"
                                                checked={!premium}
                                                onChange={handleRadioChange}
                                            />
                                            <label htmlFor="free">Free</label>
                                        </div>
                                    </div>
                                    <div className="card-footer d-flex" style={{ justifyContent: idSelected ? "space-between" : "flex-end", padding: "50px 0 0 0" }}>
                                        {
                                            idSelected &&
                                            <button className="btn btn-danger" type="button" onClick={() => handleRemoveSchedule(idSelected)}>Remover</button>
                                        }
                                        <button type="submit" disabled={loading} className="btn btn-primary me-1">
                                            {idSelected ? "Alterar" : "Salvar"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            <Breadcrumb parent="Calendário" title="Calendário" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="animated fadeIn demo-app">
                                    <Row>
                                        <Col xl={9} md={8}>
                                            <div className="demo-app-calendar" id="mycalendartest">
                                                <FullCalendar
                                                    locale="pt-br"
                                                    defaultView="timeGridWeek"
                                                    header={{
                                                        left: "prev,next today",
                                                        center: "title",
                                                        right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                                                    }}
                                                    rerenderDelay={10}
                                                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                                                    weekends={true}
                                                    events={calendarEvents}
                                                    eventClick={eventClick}
                                                    dateClick={dateClick}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Schedule;

import React, { useState, useEffect } from 'react';
import logo from '../assets/images/locale_pink.svg';
import { loginAdmin } from '../api/index'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Login, LOGIN, YourName, Password, RememberMe } from '../constant';
import { useNavigate } from 'react-router-dom';

const Signin = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();


    const loginAuth = async () => {
        try {
            const payload = {
                "email": email,
                "password": password
            }
            const resp = await loginAdmin(payload);

            if (resp.data.success) {
                localStorage.setItem('token', resp.data.token);
                localStorage.setItem('authenticated', true);
                navigate(`${process.env.PUBLIC_URL}/brands`);
            } else {
                setTimeout(() => {
                    toast.error("Usuário ou senha incorreto(s)!");
                }, 200);
            }

        } catch (error) {
            setTimeout(() => {
                toast.error("Error API response");
            }, 200);
        }
    }

    return (
        <div>
            <div className="page-wrapper">
                <div className="container-fluid p-0">
                    {/* <!-- login page start--> */}
                    <div className="authentication-main">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="auth-innerright">
                                    <div className="authentication-box">
                                        <div className="text-center">
                                            <img src={logo} alt="" /></div>
                                        <div className="card mt-4">
                                            <div className="card-body">
                                                <div className="text-center">
                                                    <h4>{LOGIN}</h4>
                                                    <h6>{"Enter your Username and Password"} </h6>
                                                </div>
                                                <form className="theme-form" >
                                                    <div className="form-group">
                                                        <label className="col-form-label pt-0">{YourName}</label>
                                                        <input className="form-control" type="email" name="email"
                                                            value={email}
                                                            onChange={e => setEmail(e.target.value)}
                                                            placeholder="Email address"
                                                        />

                                                    </div>
                                                    <div className="form-group">
                                                        <label className="col-form-label">{Password}</label>
                                                        <input className="form-control" type="password" name="password"
                                                            value={password}
                                                            onChange={e => setPassword(e.target.value)} />
                                                    </div>
                                                    <div className="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" />
                                                        <label htmlFor="checkbox1">{RememberMe}</label>
                                                    </div>
                                                    <div className="form-group form-row mt-3 mb-0 d-grid">
                                                        <button className="btn btn-primary" type="button" onClick={() => loginAuth()} >{Login}</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToastContainer />
                    {/* <!-- login page end--> */}
                </div>
            </div>
        </div>
    );
};

export default Signin;
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../../components/common/breadcrumb";
import SweetAlert from 'sweetalert2'
import { Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Previous, Next } from '../../constant'
import { TableHeaderContent } from '../../constant'
import { getAllContent, getAllTags, postContentTags, changeActiveContent } from '../../api/index';
import { Link, useNavigate } from "react-router-dom";
import { PlusSquare } from 'react-feather';
import AsyncSelect from 'react-select/async';
import { toast } from 'react-toastify';
const Content = () => {
    const [content, setContent] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsTags, setOptionsTags] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState();
    const [keyWord, setKeyWord] = useState("");
    const [contentSelected, setContentSelected] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const locale_url = process.env.REACT_APP_LOCALE_URL;

    const navigate = useNavigate();

    const fetchContent = async () => {
        const payload = {
            page: page,
            filter: {
                keyword: keyWord
            }
        }
        const resp = await getAllContent(payload);
        // console.log(resp.data.msg.brands)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setContent(resp.data.msg.content);
        setCount(resp.data.msg.total);
    };

    useEffect(() => {
        fetchContent();
    }, [page])

    useEffect(() => {
        loadOptions();
    }, [])

    const handleSearch = () => {
        setPage(1);
        fetchContent();
        setOpenModal(false);
    }

    const goCreateNewContent = () => {
        navigate('create');
    }

    const handleStatusContent = (idContent, status) => {
        const textFirst = status ? "inativar" : "ativar";
        const textSecond = status ? "Inativado" : "Ativado";

        SweetAlert.fire({
            title: `Deseja ${textFirst} o conteúdo?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                    const payload = {
                        active: !status
                    }
                    const { data } = await changeActiveContent(payload, idContent);

                    if (data.success) {
                        SweetAlert.fire({
                            title: `${textSecond} com sucesso!`,
                            icon: 'success',
                        }
                        ).then(async (result) => {
                            fetchContent();
                        })
                    } else {
                        SweetAlert.fire(
                            'Erro',
                            `${data.msg}`
                        )
                    }
                }
            })
    }

    const handleType = (type) => {
        return <span className={`badge rounded-pill badge-${type == "video" ? "success" : "warning"} p-2`}>{type}</span>
    }

    const handleStatus = (status, changeText = false) => {
        if (status) return <span className="badge rounded-pill badge-success p-2">{changeText ? "SIM" : "ATIVO"}</span>
        return <span className="badge rounded-pill badge-warning p-2">{changeText ? "NÃO" : "INATIVO"}</span>
    }

    const handleTags = (tags) => {
        return tags.map((tag) => (
            <span key={tag.idAcademyTag} className="badge rounded-pill badge-dark p-2 m-1">
                {tag.name}
            </span>
        ));
    };

    const handleBind = async () => {
        try {
            setLoading(true);
            const resp = await postContentTags({ tags: selectedOptions, idContent: contentSelected.idAcademyContent });
            setSelectedOptions([]);

            if (resp?.status == 200) {
                fetchContent();
                setOpenModal(false);
            }
        } catch (error) {
            toast.error(`error :${error}`);
        }
        setLoading(false);
    }

    const handleBindModal = async (item) => {
        setContentSelected(item)
        setOpenModal(true);
        // const resp = await updateBiId({ ...businessIntelligence });

        // console.log(resp)

        // if (resp?.status == 200) {
        //     fetchBI();
        //     setOpenFilter(false);
        //     return
        // }
        // setTimeout(() => {
        //     toast.error(`${resp?.data.msg}`);
        // }, 200);

    }

    const loadOptions = async () => {
        try {
            const resp = await getAllTags();

            if (resp.status === 200) {

                const tags = resp.data?.msg?.map(tag => ({
                    value: tag.idAcademyTag,
                    label: tag.name,
                }));

                setOptionsTags(tags);

            } else {
                toast.error('Erro ao obter os dados das tags:', resp.data.msg);
            }
        } catch (error) {
            toast.error('Erro ao obter os dados das tags:', error.message);
        }
    };

    const handleSelectChange = (selectedValues) => {
        setSelectedOptions(selectedValues || []);
    };

    return (
        <Fragment>
            <Breadcrumb parent="Academia" title="Conteúdos" />
            <Modal centered isOpen={openModal}>
                <ModalHeader>Vincular ({contentSelected?.title})</ModalHeader>
                <ModalBody>
                    <AsyncSelect
                        isMulti
                        defaultOptions={optionsTags}
                        onChange={handleSelectChange}
                        className="form-control p-0"
                        required
                        name="tags"
                        value={selectedOptions}
                        placeholder="Tags"
                    />
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <Button color="secondary" onClick={() => setOpenModal(!openModal)}>
                        Cancelar
                    </Button>
                    <Button disabled={loading} color="primary" onClick={() => handleBind()}>
                        Vincular
                    </Button>{' '}
                </ModalFooter>
            </Modal>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <h4 className="card-title mb-0">Conteúdos {count ? `(${count})` : `(${0})`}</h4>
                                            <div onClick={() => goCreateNewContent()} style={{ padding: "0.375rem 0.75rem" }} className="btn btn-success d-flex align-items-center m-l-10"> <PlusSquare className="m-r-5" />Novo</div>
                                        </div>
                                        {/* <button className="card-title mb-0 f-22 border-0 bg-transparent" onClick={() => setOpenFilter(true)}>
                                            <i className="fa fa-filter"></i>
                                            <span className="m-l-10">Filtros</span>
                                        </button> */}
                                    </div>
                                    <input
                                        className="form-control d-block m-t-20 w-10"
                                        type="text"
                                        placeholder="Procurar por nome"
                                        defaultValue={keyWord}
                                        onChange={(e) => setKeyWord(e.target.value)}
                                        onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                                    />
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter">
                                        <thead>
                                            <tr>
                                                {TableHeaderContent.map((items, i) =>
                                                    <th className="text-center" key={i}>{items}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {content && content.map((item, i) =>
                                                <tr key={i}>
                                                    {/* <td ><Link to={`${item.idAcademyContent}`} className="text-inherit">{item.title} </Link></td> */}
                                                    <td className="text-inherit">{item.title}</td>
                                                    <td className="text-center">{handleTags(item.academyTags)}</td>
                                                    <td className="text-center">{handleType(item.format)}</td>
                                                    <td className="text-center">{handleStatus(item.premium, true)}</td>
                                                    <td className="text-end">
                                                        <div className="btn-showcase">
                                                            <button className="btn btn-info btn-sm" onClick={() => handleBindModal(item)}>
                                                                <i className="fa fa-user"></i> Vincular
                                                            </button>
                                                            <button className={`btn btn-sm ${item.active ? 'btn-danger' : 'btn-success'}`} style={{ width: "120px" }} onClick={() => handleStatusContent(item.idAcademyContent, item.active)}>
                                                                <i className="fa fa-window-close"></i> {item.active ? 'Inativar' : 'Ativar'}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-body" aria-label="Page navigation example">
                                    <Pagination hidden={count == 0} aria-label="Page navigation" className="pagination-primary">
                                        <PaginationItem disabled={page == 1} onClick={() => setPage(page - 1)}>
                                            <PaginationLink >
                                                {Previous}
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            count && Array.from({ length: (Math.ceil(count / 20)) }, (_, k) => (
                                                <PaginationItem hidden={!((k + 1 <= page && k + 1 >= page - 5) || (k + 1 >= page && k + 1 <= page + 5))} active={k + 1 == page} key={k}>
                                                    <PaginationLink onClick={() => setPage(k + 1)}>
                                                        {k + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))
                                        }
                                        <PaginationItem disabled={Math.ceil(count / 20) == page} onClick={() => setPage(page + 1)}>
                                            <PaginationLink>
                                                {Next}
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Content;
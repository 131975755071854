import React, { useState, useEffect } from "react";
import Breadcrumb from "../../components/common/breadcrumb";
import { getReports } from '../../api/index';
function Report() {
    const [reports, setReports] = useState([]);

    const getToken = () => {
        const token = localStorage.getItem('token');

        return token;
    }

    const fetchReports = async () => {
        try {
            const response = await getReports();
            const foundReports = response.data.msg;

            setReports(foundReports);
        } catch (error) {
            console.error("Erro ao buscar relatórios:", error);
        }
    }

    useEffect(() => {
        fetchReports();
    }, []);


    return (
        <>
            <Breadcrumb parent="Relatórios" title="Relatórios" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h5>Relatórios</h5>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">{"Nome"}</th>
                                            <th scope="col">{"Descrição"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            reports?.map(report => {
                                                return (
                                                    <tr key={report.id}>
                                                        <th scope="row"><a href={`${process.env.REACT_APP_API_URL}/exportCsv${report.link}?token=${getToken()}`} target='_blank' rel="noreferrer">{report.title}</a></th>
                                                        <td>{report.description}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Report;

import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../common/breadcrumb';
import { formatCnpjCpf } from '../helpers/index'
import { getAllBusinessIntelligence, statusUpdateBI, searchCompanyByCnpj, updateBiId } from '../../../api/index';
import { Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter, TabContent, TabPane } from 'reactstrap';
import { Previous, Next } from '../../../constant'
import { ToastContainer, toast } from 'react-toastify';
import AsyncSelect from 'react-select/async';
import { PlusSquare } from 'react-feather';
import SweetAlert from 'sweetalert2'
import { BiTableHeader } from '../../../constant'
import { Link, useNavigate } from "react-router-dom";
const FakeBuyersList = () => {
    const [fakeBuyers, setFakeBuyers] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState();
    const [activeTab, setActiveTab] = useState('1');
    const [businessIntelligence, setBusinessIntelligence] = useState({});
    const [checkActive, setCheckActive] = useState(true);
    const [checkInactive, setCheckInactive] = useState(true);
    const [keyWord, setKeyWord] = useState("");
    const [active, setActive] = useState([0, 1]);
    const [openFilter, setOpenFilter] = useState(false);

    const navigate = useNavigate();

    const fetchBI = async () => {
        const payload = {
            page: page,
            filter: {
                active: active,
                keyword: keyWord,
            }
        }
        const resp = await getAllBusinessIntelligence(payload);
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setFakeBuyers(resp.data.msg);
        setCount(resp.data.msg.length);
    };

    useEffect(() => {
        fetchBI();
    }, [page])

    const handleSearch = () => {
        setPage(1);
        fetchBI();
        setOpenFilter(false);
    }

    const handleModal = (tab, business = {}) => {
        setOpenFilter(true);
        setActiveTab(tab);
        setBusinessIntelligence(business);
    }

    const handleBind = async () => {
        const resp = await updateBiId({ ...businessIntelligence });

        console.log(resp)

        if (resp?.status == 200) {
            fetchBI();
            setOpenFilter(false);
            return
        }
        setTimeout(() => {
            toast.error(`${resp?.data.msg}`);
        }, 200);

    }

    const handleCheckBox = (type, value) => {
        switch (type) {
            case "active":
                let newActive = active;
                value ? newActive.push(1) : newActive = active.filter(e => { return e !== 1 });
                setActive(newActive);
                setCheckActive(!checkActive);
                break;
            case "inactive":
                let newInactive = active;
                value ? newInactive.push(0) : newInactive = active.filter(e => { return e !== 0 });
                setActive(newInactive);
                setCheckInactive(!checkInactive);
                break;
        }
    }

    const loadOptions = async (inputValue, callback) => {
        if (inputValue.length >= 14) {
            const resp = await searchCompanyByCnpj({ cnpj: inputValue });

            callback(
                [{
                    value: resp.data.msg.cnpj,
                    label: `${resp.data.msg.name} - ${(formatCnpjCpf(resp.data.msg.cnpj))}`
                }]
            );
        }
    };

    const goCreateNewBuyer = () => {
        navigate('/bi/create');
    }

    const handleStatusCompany = (companyId, status) => {
        const textFirst = status ? "inativar" : "ativar";
        const textSecond = status ? "Inativado" : "Ativado";
        SweetAlert.fire({
            title: `Deseja ${textFirst} o Cadastro?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
        })
            .then(async (result) => {
                if (result.value) {
                    const { data } = await statusUpdateBI({ id: companyId, sit_active: !status });

                    if (data.success) {
                        SweetAlert.fire({
                            title: `${textSecond} com sucesso!`,
                            icon: 'success',
                        }
                        ).then(async (result) => {
                            fetchBI();
                        })
                    } else {
                        SweetAlert.fire(
                            'Erro',
                            `${data.msg}`
                        )
                    }
                }
            })
    }

    // const handleLogin = async (email) => {
    //     const { data: data } = await userLoginInAdmin({ email: email });
    //     if (data) {
    //         const user = { ...data.data, token: data.token }
    //         window.open(`${locale_url}/login?user=${encodeURIComponent(JSON.stringify(user))}`);
    //     }
    // }

    const handleStatus = (status, changeText = false) => {
        if (status) return <span className="badge rounded-pill badge-success p-2">{changeText ? "SIM" : "ATIVO"}</span>
        return <span className="badge rounded-pill badge-warning p-2">{changeText ? "NÃO" : "INATIVO"}</span>
    }

    return (
        <Fragment>
            <Breadcrumb parent="Varejistas Não Cadastrados" title="Varejistas Não Cadastrados" />
            <Modal centered isOpen={openFilter}>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <ModalHeader>Filtros - (Varejistas Não Cadastrados)</ModalHeader>
                        <ModalBody>
                            <div className="d-flex flex-wrap p-2">
                                <div className="col">
                                    <div className="checkbox checkbox-solid-primary">
                                        <input id="active" checked={checkActive} onChange={(e) => handleCheckBox("active", e.target.checked)} type="checkbox" />
                                        <label className="user-select-none" htmlFor="active">Ativos</label>
                                    </div>
                                    <div className="checkbox checkbox-solid-primary">
                                        <input id="inactive" checked={checkInactive} onChange={(e) => handleCheckBox("inactive", e.target.checked)} type="checkbox" />
                                        <label className="user-select-none" htmlFor="inactive">Inativos</label>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between">
                            <Button color="secondary" onClick={() => setOpenFilter(!openFilter)}>
                                Cancelar
                            </Button>
                            <Button color="primary" onClick={() => handleSearch()}>
                                Procurar
                            </Button>{' '}
                        </ModalFooter>
                    </TabPane>
                    <TabPane tabId="2">
                        <ModalHeader>Vincular ({businessIntelligence.name})</ModalHeader>
                        <ModalBody>
                            <AsyncSelect loadOptions={loadOptions} onChange={(e) => setBusinessIntelligence({ ...businessIntelligence, cnpj: e.value })} className="form-control p-0" required name="cnpj" type="text"
                                placeholder="CNPJ" />
                        </ModalBody>
                        <ModalFooter className="d-flex justify-content-between">
                            <Button color="secondary" onClick={() => setOpenFilter(!openFilter)}>
                                Cancelar
                            </Button>
                            <Button color="primary" onClick={() => handleBind()}>
                                Vincular
                            </Button>{' '}
                        </ModalFooter>
                    </TabPane>
                </TabContent>
            </Modal>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <h4 className="card-title mb-0">Varejistas Não Cadastrados {count ? `(${count})` : "(0)"}</h4>
                                            <div onClick={() => goCreateNewBuyer()} style={{padding: "0.375rem 0.75rem"}} className="btn btn-success d-flex align-items-center m-l-10"> <PlusSquare className="m-r-5" />Novo</div>
                                        </div>
                                        <button className="card-title mb-0 f-22 border-0 bg-transparent" onClick={() => handleModal("1")}>
                                            <i className="fa fa-filter"></i>
                                            <span className="m-l-10">Filtros</span>
                                        </button>
                                    </div>
                                    <input
                                        className="form-control d-block m-t-20 w-10"
                                        type="text"
                                        placeholder="Procurar por nome"
                                        defaultValue={keyWord}
                                        onChange={(e) => setKeyWord(e.target.value)}
                                        onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                                    />
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter">
                                        <thead>
                                            <tr>
                                                {BiTableHeader.map((items, i) =>
                                                    <th className="text-center" key={i}>{items}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {fakeBuyers && fakeBuyers.map((items, i) =>
                                                <tr key={i}>
                                                    <td ><Link to={`${items.id}`} className="text-inherit">{items.name} </Link></td>
                                                    <td className="text-center">{handleStatus(items.logo, true)}</td>
                                                    <td className="text-end">
                                                        <div className="btn-showcase">
                                                            <button className="btn btn-info btn-sm" onClick={() => handleModal("2", { name: items.name, id: items.id })}>
                                                                <i className="fa fa-address-book"></i> Vincular
                                                            </button>
                                                            <button className={`btn btn-sm ${items.sit_active ? 'btn-danger' : 'btn-success'}`} style={{ width: "120px" }} onClick={() => handleStatusCompany(items.id, items.sit_active)}>
                                                                <i className="fa fa-window-close"></i> {items.sit_active ? 'Inativar' : 'Ativar'}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-body" aria-label="Page navigation example">
                                    <Pagination hidden={count == 0} aria-label="Page navigation" className="pagination-primary">
                                        <PaginationItem disabled={page == 1} onClick={() => setPage(page - 1)}>
                                            <PaginationLink >
                                                {Previous}
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            count && Array.from({ length: (Math.ceil(count / 20)) }, (_, k) => (
                                                <PaginationItem hidden={!((k + 1 <= page && k + 1 >= page - 5) || (k + 1 >= page && k + 1 <= page + 5))} active={k + 1 == page} key={k}>
                                                    <PaginationLink onClick={() => setPage(k + 1)}>
                                                        {k + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))
                                        }
                                        <PaginationItem disabled={Math.ceil(count / 20) == page} onClick={() => setPage(page + 1)}>
                                            <PaginationLink>
                                                {Next}
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </Fragment>
    );
};

export default FakeBuyersList;
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../../components/common/breadcrumb";
import { postCreateVimeoVideo } from '../../api/index';
import { useNavigate } from "react-router-dom";
import { useForm } from 'react-hook-form';
import DefaultSpinner from '../../components/locale/helpers/defaultSpinner';
import { toast } from 'react-toastify';
const CreateContent = () => {
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [fileImg, setFileImg] = useState();
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedThumbnail, setSelectedThumbnail] = useState(null);

    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmitForm = async (data) => {
        if (!selectedFile || !selectedThumbnail) return;

        setLoading(true);
        const resp = await postCreateVimeoVideo(data, selectedFile, selectedThumbnail);

        if (resp?.status == 200) {
            toast.success(`Sucesso!`);
        } else {
            toast.error(`Error: ${resp?.data.msg}`);
        }

        setLoading(false);
        // Reset the form after successful submission
        // reset();
    };

    const readUrl = (event) => {
        if (event.target.files.length === 0)
            return;

        const selectedFile = event.target.files[0];
        setSelectedThumbnail(selectedFile)

        if (selectedFile.type.match(/image\/*/) == null) {
            return;
        }

        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);
        reader.onload = (_event) => {
            setFileImg(reader.result)
        }
    }

    return (
        <Fragment>
            <Breadcrumb parent="Academia" title="Criação" />
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card">
                                <form onSubmit={handleSubmit(handleSubmitForm)}>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <div className="user-image mb-3">
                                                    <div className="row avatar d-flex justify-content-center">
                                                        <label>Thumbnail:</label>
                                                        <label htmlFor="image-avatar">
                                                            {/* <img className="pro border" style={{ maxWidth: "225px", maxHeight: "225px" }} role="button" alt="" src={buyer?.logo ? buyer?.logo : one} data-intro="This is Profile image" /> */}
                                                            {
                                                                fileImg ?
                                                                    <img className="pro border" style={{ width: "100%", height: "225px", objectFit: "fit" }} role="button" alt="" src={fileImg} data-intro="This is Thumbnail image" />
                                                                    :
                                                                    <div className="empty-img">
                                                                        <span>Thumbnail Aqui</span>
                                                                    </div>

                                                            }
                                                            <input className="pencil d-none" id="image-avatar" type="file" accept="image/png, image/jpeg" onChange={(e) => readUrl(e)} />
                                                        </label>
                                                    </div>
                                                </div>
                                                <input type="file" accept="video/*" onChange={handleFileChange} />
                                            </div>
                                            <div className="col-lg-8">
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Título:</label>
                                                    <input type="text" className="form-control" name="title" {...register('title', { required: 'Campo obrigatório' })} />
                                                    {
                                                        errors?.title?.message && <span className="danger-text mt-6">{errors?.title?.message}</span>
                                                    }
                                                </div>
                                                <div className="form-group mb-3">
                                                    <label className="form-label">Descrição:</label>
                                                    <textarea rows={3} type="text" className="form-control" name="description" {...register('description', { required: 'Campo obrigatório' })} />
                                                    {
                                                        errors?.description?.message && <span className="danger-text mt-6">{errors?.description?.message}</span>
                                                    }
                                                </div>
                                                <div className="d-flex">
                                                    <div className="form-group m-checkbox-inline mb-0 ms-1">
                                                        <div className="checkbox checkbox-solid-info" >
                                                            {/* <input id="solid1" type="checkbox" name="status" onChange={(e) => handleCompany(e)} checked={company.status} /> */}
                                                            <input id="solid1" type="checkbox" name="status" {...register('status')} />
                                                            <label className="user-select-none" htmlFor="solid1">Premium</label>
                                                        </div>
                                                    </div>
                                                    <div className="form-group m-checkbox-inline mb-0 ms-1">
                                                        <div className="checkbox checkbox-solid-info" >
                                                            {/* <input id="solid2" type="checkbox" name="test" onChange={(e) => handleCompany(e)} checked={company.test} /> */}
                                                            <input id="solid2" type="checkbox" name="active" {...register('active')} />
                                                            <label className="user-select-none" htmlFor="solid2">Ativo</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-footer text-end" style={{ border: "none", marginTop: "auto" }}>
                                            <button className="btn btn-primary me-2" disabled={loading} type="submit">{loading ? <DefaultSpinner /> : "Salvar"}</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    );
};

export default CreateContent;
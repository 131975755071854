import React, { Fragment } from 'react';
import Header from './common/header-component/header';
import Sidebar from './common/sidebar-component/sidebar';
import RightSidebar from './common/right-sidebar';
import Footer from './common/footer';
// import ThemeCustomizer from './common/theme-customizer'
import { ToastContainer } from 'react-toastify';
import Loader from './common/loader';
import { Outlet } from 'react-router-dom';

const AppLayout = (props) => {
    return (
        <Fragment>
            <Loader />
            <div className="page-wrapper">
                <div className="page-body-wrapper">
                    <Header />
                    <Sidebar />
                    <RightSidebar />
                    <div className="page-body">
                        <Outlet />
                    </div>
                    <Footer />
                </div>
            </div>
            <ToastContainer autoClose={2000}/>
        </Fragment>
    );
}

export default AppLayout;
import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from "../../components/common/breadcrumb";
import SweetAlert from 'sweetalert2'
import { Pagination, PaginationItem, PaginationLink, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Previous, Next } from '../../constant'
import { TableHeaderContent } from '../../constant'
import { getAllContent } from '../../api/index';
import { Link, useNavigate } from "react-router-dom";
import { PlusSquare } from 'react-feather';
const Tag = () => {
    const [content, setContent] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState();
    const [checkActive, setCheckActive] = useState(true);
    const [checkInactive, setCheckInactive] = useState(true);
    const [boost, setBoost] = useState(false);
    const [checkPremium, setCheckPremium] = useState(true);
    const [checkFree, setCheckFree] = useState(true);
    const [checkTest, setCheckTest] = useState(true);
    const [keyWord, setKeyWord] = useState("");
    const [checkNotTest, setCheckNotTest] = useState(true);
    const [premium, setPremium] = useState([0, 1]);
    const [active, setActive] = useState([0, 1]);
    const [test, setTest] = useState([0, 1]);
    const [openFilter, setOpenFilter] = useState(false);
    const locale_url = process.env.REACT_APP_LOCALE_URL;

    const navigate = useNavigate();

    const fetchContent = async () => {
        const payload = {
            page: page,
            // filter: {
            //     premium: premium,
            //     active: active,
            //     test: test,
            //     keyword: keyWord.includes("@") ? keyWord : keyWord.replaceAll("/", "").replaceAll(".", "").replaceAll("-", ""),
            //     boost: boost ? true : false
            // }
        }
        const resp = await getAllContent(payload);
        // console.log(resp.data.msg.brands)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        setContent(resp.data.msg.content);
        setCount(resp.data.msg.total);
    };

    useEffect(() => {
        fetchContent();
    }, [page])

    const handleSearch = () => {
        setPage(1);
        fetchContent();
        setOpenFilter(false);
    }

    const goCreateNewContent = () => {
        navigate('create');
    }

    const handleStatusContent = (companyId, status) => {
        // const textFirst = status ? "inativar" : "ativar";
        // const textSecond = status ? "Inativado" : "Ativado";
        // SweetAlert.fire({
        //     title: `Deseja ${textFirst} o conteúdo?`,
        //     icon: 'warning',
        //     showCancelButton: true,
        //     confirmButtonText: 'Sim',
        //     cancelButtonText: 'Não',
        //     reverseButtons: true
        // })
        //     .then(async (result) => {
        //         if (result.value) {
        //             const { data } = await updateDeleteCompany({ adminTokenNeeded: true, companyId: companyId });

        //             if (data.success) {
        //                 SweetAlert.fire({
        //                     title: `${textSecond} com sucesso!`,
        //                     icon: 'success',
        //                 }
        //                 ).then(async (result) => {
        //                     fetchContent();
        //                 })
        //             } else {
        //                 SweetAlert.fire(
        //                     'Erro',
        //                     `${data.msg}`
        //                 )
        //             }
        //         }
        //     })
    }

    const handleCheckBox = (type, value) => {
        switch (type) {
            case "active":
                let newActive = active;
                console.log(newActive)
                value ? newActive.push(1) : newActive = active.filter(e => { return e !== 1 });
                setActive(newActive);
                setCheckActive(!checkActive);
                break;
            case "inactive":
                let newInactive = active;
                value ? newInactive.push(0) : newInactive = active.filter(e => { return e !== 0 });
                setActive(newInactive);
                setCheckInactive(!checkInactive);
                break;
            case "boost":
                setBoost(!boost);
                break;
            case "premium":
                let newPremium = premium;
                value ? newPremium.push(1) : newPremium = premium.filter(e => { return e !== 1 });
                setPremium(newPremium);
                setCheckPremium(!checkPremium);
                break;
            case "free":
                let newFree = premium;
                value ? newFree.push(0) : newFree = premium.filter(e => { return e !== 0 });
                setPremium(newFree);
                setCheckFree(!checkFree);
                break;
            case "test":
                let newTest = test;
                value ? newTest.push(1) : newTest = test.filter(e => { return e !== 1 });
                setTest(newTest);
                setCheckTest(!checkTest);
                break;
            case "notTest":
                let newNotTest = test;
                value ? newNotTest.push(0) : newNotTest = test.filter(e => { return e !== 0 });
                setTest(newNotTest);
                setCheckNotTest(!checkNotTest);
                break;
        }
    }

    const dateFormat = (date) => {
        return new Date(date).toLocaleDateString();
    }

    const handleLogin = async (email) => {
        // const { data: data } = await userLoginInAdmin({ email: email });
        // if (data) {
        //     const user = { ...data.data, token: data.token }
        //     window.open(`${locale_url}/login?user=${encodeURIComponent(JSON.stringify(user))}`);
        // }
    }

    const handleType = (type) => {
        return <span className={`badge rounded-pill badge-${type == "video" ? "success" : "warning"} p-2`}>{type}</span>
    }

    const handleStatus = (status, changeText = false) => {
        if (status) return <span className="badge rounded-pill badge-success p-2">{changeText ? "SIM" : "ATIVO"}</span>
        return <span className="badge rounded-pill badge-warning p-2">{changeText ? "NÃO" : "INATIVO"}</span>
    }

    const handleTags = (tags) => {
        return tags.map((tag) => (
            <span key={tag.idAcademyTag} className="badge rounded-pill badge-dark p-2 m-1">
                {tag.name}
            </span>
        ));
    };

    return (
        <Fragment>
            <Breadcrumb parent="Academia" title="Conteúdos" />
            <Modal centered isOpen={openFilter}>
                <ModalHeader>Filtros - (Marca)</ModalHeader>
                <ModalBody>
                    <div className="d-flex flex-wrap p-2">
                        <div className="col">
                            <div className="checkbox checkbox-solid-primary">
                                <input id="active" checked={checkActive} onChange={(e) => handleCheckBox("active", e.target.checked)} type="checkbox" />
                                <label htmlFor="active">Ativos</label>
                            </div>
                            <div className="checkbox checkbox-solid-primary">
                                <input id="inactive" checked={checkInactive} onChange={(e) => handleCheckBox("inactive", e.target.checked)} type="checkbox" />
                                <label htmlFor="inactive">Inativos</label>
                            </div>
                            <div className="checkbox checkbox-solid-primary">
                                <input id="incomplete" checked={boost} onChange={(e) => handleCheckBox("boost", e.target.checked)} type="checkbox" />
                                <label htmlFor="incomplete">Boost</label>
                            </div>
                            <div className="checkbox checkbox-solid-primary">
                                <input id="premium" type="checkbox" checked={checkPremium} onChange={(e) => handleCheckBox("premium", e.target.checked)} />
                                <label htmlFor="premium">Premium</label>
                            </div>
                        </div>
                        <div className="col">
                            <div className="checkbox checkbox-solid-primary">
                                <input id="free" type="checkbox" checked={checkFree} onChange={(e) => handleCheckBox("free", e.target.checked)} />
                                <label htmlFor="free">Não Premium</label>
                            </div>
                            <div className="checkbox checkbox-solid-primary">
                                <input id="test" type="checkbox" checked={checkTest} onChange={(e) => handleCheckBox("test", e.target.checked)} />
                                <label htmlFor="test">Teste</label>
                            </div>
                            <div className="checkbox checkbox-solid-primary">
                                <input id="notTest" type="checkbox" checked={checkNotTest} onChange={(e) => handleCheckBox("notTest", e.target.checked)} />
                                <label htmlFor="notTest">Não Teste</label>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-between">
                    <Button color="secondary" onClick={() => setOpenFilter(!openFilter)}>
                        Cancel
                    </Button>
                    <Button color="primary" onClick={() => handleSearch()}>
                        Procurar
                    </Button>{' '}
                </ModalFooter>
            </Modal>
            <div className="container-fluid">
                <div className="edit-profile">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <h4 className="card-title mb-0">Conteúdos {count && `(${count})`}</h4>
                                            <div onClick={() => goCreateNewContent()} style={{ padding: "0.375rem 0.75rem" }} className="btn btn-success d-flex align-items-center m-l-10"> <PlusSquare className="m-r-5" />Novo</div>
                                        </div>
                                        {/* <button className="card-title mb-0 f-22 border-0 bg-transparent" onClick={() => setOpenFilter(true)}>
                                            <i className="fa fa-filter"></i>
                                            <span className="m-l-10">Filtros</span>
                                        </button> */}
                                    </div>
                                    <input
                                        className="form-control d-block m-t-20 w-10"
                                        type="text"
                                        placeholder="Procurar por nome"
                                        defaultValue={keyWord}
                                        onChange={(e) => setKeyWord(e.target.value)}
                                        onKeyDown={(e) => { if (e.key === 'Enter') handleSearch() }}
                                    />
                                </div>
                                <div className="table-responsive">
                                    <table className="table card-table table-vcenter">
                                        <thead>
                                            <tr>
                                                {TableHeaderContent.map((items, i) =>
                                                    <th className="text-center" key={i}>{items}</th>
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {content && content.map((item, i) =>
                                                <tr key={i}>
                                                    {/* <td ><Link to={`${item.idAcademyContent}`} className="text-inherit">{item.title} </Link></td> */}
                                                    <td className="text-inherit">{item.title}</td>
                                                    <td className="text-center">{handleTags(item.academyTags)}</td>
                                                    <td className="text-center">{handleType(item.format)}</td>
                                                    <td className="text-center">{handleStatus(item.premium, true)}</td>
                                                    <td className="text-end">
                                                        <div className="btn-showcase">
                                                            <button className="btn btn-info btn-sm" onClick={() => handleLogin(item.users[0].email)}>
                                                                <i className="fa fa-user"></i> Vincular
                                                            </button>
                                                            <button className={`btn btn-sm ${item.active ? 'btn-danger' : 'btn-success'}`} style={{ width: "120px" }} onClick={() => handleStatusContent(item.id, item.sit_active)}>
                                                                <i className="fa fa-window-close"></i> {item.active ? 'Inativar' : 'Ativar'}
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="card-body" aria-label="Page navigation example">
                                    <Pagination hidden={count == 0} aria-label="Page navigation" className="pagination-primary">
                                        <PaginationItem disabled={page == 1} onClick={() => setPage(page - 1)}>
                                            <PaginationLink >
                                                {Previous}
                                            </PaginationLink>
                                        </PaginationItem>
                                        {
                                            count && Array.from({ length: (Math.ceil(count / 20)) }, (_, k) => (
                                                <PaginationItem hidden={!((k + 1 <= page && k + 1 >= page - 5) || (k + 1 >= page && k + 1 <= page + 5))} active={k + 1 == page} key={k}>
                                                    <PaginationLink onClick={() => setPage(k + 1)}>
                                                        {k + 1}
                                                    </PaginationLink>
                                                </PaginationItem>
                                            ))
                                        }
                                        <PaginationItem disabled={Math.ceil(count / 20) == page} onClick={() => setPage(page + 1)}>
                                            <PaginationLink>
                                                {Next}
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Tag;